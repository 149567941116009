import { Fragment } from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class LN2Transaction extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			trans: {
				TRANS_PK: ''
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadTransaction();
	}

	loadTransaction(event) {
		var self = this;
		
		if (this.props.params.transId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'LN2_TRANSACTIONS_VW',
					search: { Attribute: 'TRANS_PK', Operator: '=', LongValue: this.props.params.transId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var trans = data.Data[0];
						trans.SERVICE_ID = trans.SERVICE_ID === 22 ? 'Y' : null;
						self.mvc2js(trans, transColumns);
							
						self.mergeState({
							trans: trans,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Transaction Error', data.Data.length + ' Transactions found for ID ' + self.props.params.transId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				trans: {
					TRANS_PK: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var trans = this.copyObject(this.state.trans);
		this.setByAccessor(trans, accessor, value);
		this.mergeState({ trans: trans, renderKey: this.state.renderKey + 1 });
	}
		
	deleteTransaction(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Transaction', 'Delete Transaction ' + this.props.params.transId + '?', (okClicked) => {
			if (okClicked) {
				// prepare trans for deletion		
				var trans = {
					TRANS_ID: self.props.params.transId,
					DELETE_FLAG: 'Y'
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'LIQUID_AIR.LN2_TRANSACTION', data: trans }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Transaction {self.props.params.transId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;
		var url;

		var trans = this.copyObject(this.state.trans);
		this.js2mvc(trans, transColumns);
		trans.SERVICE_ID = trans.SERVICE_ID === 'Y' ? 22 : 20;
		trans.DELETE_FLAG = null;

		if (!this.props.params.transId) {
			url = '/Home/Insert';
			trans.TRANS_PK = 'ln2_trans_pk_seq.nextval';
			trans.TRANS_ID = 'ln2_manual_seq.nextval';
			trans.CREATE_DATE = this.dateTimeToMVC(new Date());
			trans.CREATE_BY = this.props.user.name;
		} else {
			url = '/Home/Update';
			trans.MODIF_DATE = this.dateTimeToMVC(new Date());
			trans.MODIF_BY = this.props.user.name;
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'LIQUID_AIR.LN2_TRANSACTION', data: trans }
		}).done(function (data) {
			if (data.Success) {
				var transId = self.props.params.transId || data.ID;
				var verb = self.props.params.transId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Transaction <Link to={'/HeLAD/LN2Transactions/' + transId}>{transId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Transaction {this.state.trans.TRANS_PK}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={transColumns} data={this.state.trans} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
						{this.state.editable && this.props.params.transId && <Button id="delete" variant="warning" type="button" className="float-end"
						  style={{ marginRight: '20px' }} onClick={(event) => this.deleteTransaction(event)}>Delete</Button>} 
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const transColumns = [
	{ Header: 'Transaction ID', accessor: 'TRANS_ID', row: 0, col: 0, editable: false },
	{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 1 },
	{ Header: 'First Name', accessor: 'FNAME', row: 0, col: 2 },
	{ Header: 'Cal1 ID', accessor: 'CARD_ID', row: 0, col: 3 },
	{ Header: 'User PI', accessor: 'PI_ID', type: 'select', row: 1, col: 0,
		options: { value: 'PERSON_ID', label: 'PI_NAME', entity: 'LIQUID_AIR.LN2_PI_SELECT_VW', order: ['LNAME','FNAME'] }},
	{ Header: 'Speedtype', accessor: 'COST_CENTER', type: 'select', row: 1, col: 1,
		options: { value: 'COST_CENTER', label: 'COST_CENTER', entity: 'LIQUID_AIR.LN2_SPEEDTYPE_SELECT_VW', order: 'COST_CENTER',
			search: { Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'PI_ID' }}},
	{ Header: 'Volume to Bill', accessor: 'VOLUME_TO_BILL', row: 1, col: 2, subscript: 'Liter(s)' },
	{ Header: 'Station', accessor: 'STATION', type: 'select', row: 1, col: 3,
		options: [{ value: 1, label: '1' },{ value: 2, label: '2' },{ value: 3, label: '3' },{ value: 4, label: 'Bulk (Physics)' }]},
	{ Header: 'Date', accessor: 'TRANS_DATE', type: 'date', row: 2, col: 0 },
	{ Header: 'Billed', accessor: 'DATE_BILLED', type: 'date', row: 2, col: 1 },
	{ Header: 'Comments', accessor: 'MODIF_ACTION', type: 'textarea', row: 2, col: 2 },
	{ Header: 'Bulk', accessor: 'SERVICE_ID', type: 'checkbox', row: 2, col: 3 },
	{ Header: 'Last Modified', accessor: 'MODIFIED', row: 3, col: 0, editable: false }
];

export default withRouter(LN2Transaction);
