import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';

class Home extends ChemComponent {	
	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 0
		};
	}

	render() {
		return(
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Reuse</span>
				  <Container>
					<Row className='align-items-center' style={{ borderTop: '1px solid #eee' }}>
					  <Col xs={12}>
						This is the reuse page
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		);
	}
}

export default withRouter(Home);