import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Routes, Route } from "react-router-dom";
import ChemComponent from '../ChemComponent';
import ChemHeader from '../ChemHeader';
import TreeMenu from '../TreeMenu';
import { withAdminDefaultRoute } from './withAdminDefaultRoute';
import Errors from './Errors';
import ErrorDetail from './ErrorDetail';
import DataSources from './DataSources';
import Tasks from './Tasks';
import Task from './Task';
import RemoteLogout from './RemoteLogout';
import Confirm from '../Confirm';
import GradApps from './GradApps';
import QualExams from './QualExams';
import GSI from './GSI';
import Scramble from './Scramble';
import LogAnalysis from './LogAnalysis';
import Debug from './Debug';
import Email from './Email';
import LDAP from './LDAP';
import BearBuy from './BearBuy';
import UpdateEmail from './UpdateEmail';
import FirstYearAdvisors from './FirstYearAdvisors';
import DivisionConcentration from './DivisionConcentration';
import Corrections from './Corrections';

class Admin extends ChemComponent {
  	constructor(props) {
		super(props);
				
		this.state = {
			fullScreen: false,
			table: 'menu',
			confirm: ''
		};
	}

	showConfirmation(message) {		
		this.mergeState({
			confirm: message
		}, () => this.props.navigate('/WebAdmin/Confirm'));
	}

	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='Web App Admin' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.mergeState({ fullScreen: fullScreen })} />
			<Container fluid style={{ paddingTop: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={2}>
						<TreeMenu parent={this} menuItems={leftMenuItems} selectedRoute={this.props.location.pathname} />
					</Col>}
					<Col xs={this.state.fullScreen ? 12 : 10}>
						<Routes>
							<Route path={'/RemoteLogout'} element={props => <RemoteLogout parent={this} user={this.props.user} />} />
							<Route path={'/Tasks'} element={<Tasks parent={this} user={this.props.user} />} />
							<Route path={'/Tasks/:taskId'} element={<Task parent={this} user={this.props.user} />} />
							<Route path={'/DataSources'} element={<DataSources parent={this} user={this.props.user} />} />
							<Route path={'/Errors'} element={<Errors parent={this} user={this.props.user} />} />
							<Route path={'/Errors/:errorId'} element={<ErrorDetail parent={this} user={this.props.user} />} />
							<Route path={'/GradApps'} element={<GradApps parent={this} user={this.props.user} />} />
							<Route path={'/QualExams'} element={<QualExams parent={this} user={this.props.user} />} />
							<Route path={'/GSI'} element={<GSI parent={this} user={this.props.user} />} />
							<Route path={'/LogAnalysis'} element={<LogAnalysis parent={this} user={this.props.user} />} />
							<Route path={'/Scramble'} element={<Scramble parent={this} user={this.props.user} />} />
							<Route path={'/Email'} element={<Email parent={this} user={this.props.user} />} />
							<Route path={'/LDAP'} element={<LDAP parent={this} user={this.props.user} />} />
							<Route path={'/BearBuy'} element={<BearBuy parent={this} user={this.props.user} />} />
							<Route path={'/UpdateEmail'} element={<UpdateEmail parent={this} user={this.props.user} />} />
							<Route path={'/FirstYearAdvisors'} element={<FirstYearAdvisors parent={this} user={this.props.user} />} />
							<Route path={'/DivisionConcentration'} element={<DivisionConcentration parent={this} user={this.props.user} />} />
							<Route path={'/Corrections'} element={<Corrections parent={this} user={this.props.user} />} />
							<Route path={'/debug'} element={<Debug parent={this} user={this.props.user} />} />
							<Route path={'/confirm'} element={<Confirm parent={this} user={this.props.user} message={this.state.confirm} />} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}	

const leftMenuItems = [
	{
		route: '/WebAdmin/Errors',
		label: 'Errors'
	},
	{
		route: '/WebAdmin/DataSources',
		label: 'Data Sources'
	},
	{
		route: '/WebAdmin/Tasks',
		label: 'Tasks'
	},
	{
		route: '/WebAdmin/RemoteLogout',
		label: 'Remote Logout'
	},
	{
		route: '/WebAdmin/LogAnalysis',
		label: 'Log Analysis'
	},
	{
		route: '/WebAdmin/Debug',
		label: 'Debug'
	},
	{
		route: '/WebAdmin/Scramble',
		label: 'Team Scramble'
	},
	{
		route: '/WebAdmin/Email',
		label: 'Email Test'
	},
	{
		route: '/WebAdmin/LDAP',
		label: 'UID Lookup'
	},
	{
		route: '/WebAdmin/BearBuy',
		label: 'BearBuy Upload'
	},
	{
		route: '/WebAdmin/Corrections',
		label: 'Upload Corrections'
	},
	{
		label: 'Grad Office Uploads',
		nodes:
		[
			{
				route: '/WebAdmin/GradApps',
				label: 'Grad Applicants'
			},
			{
				route: '/WebAdmin/UpdateEmail',
				label: 'Update Student Email'
			},
			{
				route: '/WebAdmin/QualExams',
				label: 'Qual Exams'
			},
			{
				route: '/WebAdmin/GSI',
				label: 'GSI'
			},
			{
				route: '/WebAdmin/FirstYearAdvisors',
				label: 'First Year Advisors'
			},
			{
				route: '/WebAdmin/DivisionConcentration',
				label: 'Division and Concentration'
			}
		]
	}
];

export default withAdminDefaultRoute(Admin);