import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import { withBADefaultRoute } from './withBADefaultRoute';
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemHeader from '../ChemHeader';
import AddPerson from './AddPerson';
import Person from './Person';
import Role from './Role';
import People from './People';
import MyAppointments from './MyAppointments';
import MyRecord from './MyRecord';
import MyGroup from './MyGroup';
import MyGrads from './MyGrads';
import MyRequests from './MyRequests';
import AdHocPeople from './reports/AdHocPeople';
import DuplicatePeople from './reports/DuplicatePeople';
import SubmittedNewRequests from './reports/SubmittedNewRequests';
import Rooms from './Rooms';
import Room from './Room';
import RoomFunctions from './RoomFunctions';
import RoomFunction from './RoomFunction';
import RoomReport from './reports/RoomReport';
import Keys from './Keys';
import Key from './Key';
import KeyMakers from './KeyMakers';
import KeyMaker from './KeyMaker';
import KeyTypes from './KeyTypes';
import KeyType from './KeyType';
import KeyStatuses from './KeyStatuses';
import KeyStatus from './KeyStatus';
import Departments from './Departments';
import Department from './Department';
import Buildings from './Buildings';
import Building from './Building';
import PayrollTitles from './PayrollTitles';
import PayrollTitle from './PayrollTitle';
import RoleTypes from './RoleTypes';
import RoleType from './RoleType';
import OrgUnits from './OrgUnits';
import OrgUnit from './OrgUnit';
import WorkTitles from './WorkTitles';
import WorkTitle from './WorkTitle';
import Applications from './Applications';
import Application from './Application';
import Authorizations from './Authorizations';
import Authorization from './Authorization';
import AccessLevels from './AccessLevels';
import AccessLevel from './AccessLevel';
import StudentUpload from './StudentUpload';
import KeyHolders from './reports/KeyHolders';
import KeyReport from './reports/KeyReport';
import DeansList from './reports/DeansList';
import EmergencyRoomList from './reports/EmergencyRoomList';
import AddsDeletes from './reports/AddsDeletes';
import GradsPostdocs from './reports/GradsPostdocs';
import LocationList from './reports/LocationList';
import CollegeRoomContacts from './reports/CollegeRoomContacts';
import Confirm from '../Confirm';
import Restricted from '../Restricted';
import StoresApproval from './StoresApproval';
import StoresAccess from './StoresAccess';
import StudentWorkshopApproval from './StudentWorkshopApproval';
import StudentWorkshopAccess from './StudentWorkshopAccess';
import ApprovedKey from './ApprovedKey';
import IssuedKey from './IssuedKey';
import TreeMenu from '../TreeMenu';
import Help from './Help';

class BuildingAccess extends ChemComponent {
  	constructor(props) {
		super(props);
		
		// figure out what user is authorized for
		var authPeople = props.user.isDelegate || props.user.isFSA || props.user.isPI || props.user.isSAO || props.user.isSupervisor || props.user.isStoreManager || props.user.bldgMgmtAccessLevel > 0;
		var authGroup = props.user.isDelegate || props.user.isFSA || props.user.isPI || props.user.isSAO || props.user.isSupervisor;

		// copy menu items so we can edit
		var stateTreeMenuItems = JSON.parse(JSON.stringify(treeMenuItems));
		
		// remove unauthorized menu items
		if (!authPeople) this.removeRoute(stateTreeMenuItems, routes.peopleSearch);
		if (!authGroup) {
			this.removeRoute(stateTreeMenuItems, routes.myRequests);
			this.removeRoute(stateTreeMenuItems, routes.myGroup);
		}
		if (!props.user.isStoreManager) {
			this.removeRoute(stateTreeMenuItems, routes.storesApproval);
			this.removeRoute(stateTreeMenuItems, routes.storesAccess);
		}
		if (!props.user.isStudentWorkshopManager) {
			this.removeRoute(stateTreeMenuItems, routes.workshopApproval);
			this.removeRoute(stateTreeMenuItems, routes.workshopAccess);
		}
		if (!props.user.isSAO) this.removeRoute(stateTreeMenuItems, routes.myGrads);
		if (props.user.bldgMgmtAccessLevel < 1) {
			this.removeRoute(stateTreeMenuItems, routes.adHocPeopleReport);
			this.removeRoute(stateTreeMenuItems, routes.submittedNewRequests);
			this.removeRoute(stateTreeMenuItems, routes.rooms);
			this.removeRoute(stateTreeMenuItems, routes.roomFunctions);
			this.removeRoute(stateTreeMenuItems, routes.roomReport);
			this.removeRoute(stateTreeMenuItems, routes.keys);
			this.removeRoute(stateTreeMenuItems, routes.keyMakers);
			this.removeRoute(stateTreeMenuItems, routes.keyTypes);
			this.removeRoute(stateTreeMenuItems, routes.keyStatuses);
			this.removeRoute(stateTreeMenuItems, routes.departments);
			this.removeRoute(stateTreeMenuItems, routes.buildings);
			this.removeRoute(stateTreeMenuItems, routes.payrollTitles);
			this.removeRoute(stateTreeMenuItems, routes.roleTypes);
			this.removeRoute(stateTreeMenuItems, routes.orgUnits);
			this.removeRoute(stateTreeMenuItems, routes.workTitles);
			this.removeRoute(stateTreeMenuItems, routes.keyHolders);
			this.removeRoute(stateTreeMenuItems, routes.keyReport);
			this.removeRoute(stateTreeMenuItems, routes.deansList);
			this.removeRoute(stateTreeMenuItems, routes.emergencyRoomList);
			this.removeRoute(stateTreeMenuItems, routes.addsDeletesReport);
			this.removeRoute(stateTreeMenuItems, routes.gradsPostdocs);
			this.removeRoute(stateTreeMenuItems, routes.locationList);
			this.removeRoute(stateTreeMenuItems, routes.collegeRoomContacts);
			this.removeRoute(stateTreeMenuItems, routes.studentUpload);
		}
		if (props.user.bldgMgmtAccessLevel !== 1) {
			this.removeRoute(stateTreeMenuItems, routes.applications);
			this.removeRoute(stateTreeMenuItems, routes.accessLevels);
			this.removeRoute(stateTreeMenuItems, routes.authorizations);
		}
		
		this.state = {
			fullScreen: false,
			authPeople: authPeople,
			authGroup: authGroup,
			treeMenuItems: stateTreeMenuItems,
			confirm: ''
		};
	}
	
	removeRoute(menuItems, route) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRoute(menuItems[i].nodes, route);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].route && menuItems[i].route.toUpperCase() === route.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	showConfirmation(message) {		
		this.mergeState({
			confirm: message
		}, () => this.props.navigate('/BuildingAccess/Confirm'));
	}
	
	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='College Access System' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.mergeState({ fullScreen: fullScreen })}
				showHelp={() => this.props.navigate('/BuildingAccess/Help', { helpPath: this.props.location.pathname })} />
			<Container fluid style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={2}>
						<TreeMenu parent={this} menuItems={this.state.treeMenuItems} selectedRoute={this.props.location.pathname} />
					</Col>}
					<Col xs={this.state.fullScreen ? 12 : 10}>
						<Routes>
							<Route path={'/people'} element={
								<Restricted authorized={this.state.authPeople || this.props.user.isAdmin}>
									<People parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/myrecord'} element={<MyRecord parent={this} user={this.props.user} />} />
							<Route path={'/myappointments'} element={<MyAppointments parent={this} user={this.props.user} />} />
							<Route path={'/myrequests'} element={
								<Restricted authorized={this.state.authGroup || this.props.user.isAdmin}>
									<MyRequests parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/mygroup'} element={
								<Restricted authorized={this.state.authGroup || this.props.user.isAdmin}>
									<MyGroup parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/storesapproval'} element={
								<Restricted authorized={this.props.user.isStoreManager || this.props.user.isAdmin}>
									<StoresApproval parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/storesaccess'} element={
								<Restricted authorized={this.props.user.isStoreManager || this.props.user.isAdmin}>
									<StoresAccess parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/studentworkshopapproval'} element={
								<Restricted authorized={this.props.user.isStudentWorkshopManager || this.props.user.isAdmin}>
									<StudentWorkshopApproval parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/studentworkshopaccess'} element={
								<Restricted authorized={this.props.user.isStudentWorkshopManager || this.props.user.isAdmin}>
									<StudentWorkshopAccess parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/mygrads'} element={
								<Restricted authorized={this.props.user.isSAO || this.props.user.isAdmin}>
									<MyGrads parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/people/new'} element={
								<Restricted authorized={this.state.authPeople || this.props.user.isAdmin}>
									<AddPerson parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/people/:personId'} element={
								<Restricted authorized={this.state.authPeople || this.props.user.isAdmin}>
									<Person parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/roles/new/:personId'} element={
								<Restricted authorized={this.state.authPeople || this.props.user.isAdmin}>
									<Role parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/roles/:roleId'} element={
								<Restricted authorized={this.state.authPeople || this.props.user.isAdmin}>
									<Role parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/issuedkeys/:keyAdminId'} element={
								<Restricted authorized={this.state.authPeople || this.props.user.isAdmin}>
									<IssuedKey parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/approvedkeys/:reqRoleId/:roomId'} element={
								<Restricted authorized={this.state.authPeople || this.props.user.isAdmin}>
									<ApprovedKey parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/adhocpeople'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<AdHocPeople parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/duplicatepeople'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<DuplicatePeople parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/submittednewrequests'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<SubmittedNewRequests parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/roomreport'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<RoomReport parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/rooms'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Rooms parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/rooms/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Room parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/rooms/:roomId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Room parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/roomfunctions'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<RoomFunctions parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/roomfunctions/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<RoomFunction parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/roomfunctions/:functionId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<RoomFunction parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keys'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Keys parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keys/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Key parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keys/:keyId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Key parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keymakers'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyMakers parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keymakers/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyMaker parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keymakers/:keyMakerId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyMaker parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keytypes'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyTypes parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keytypes/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyType parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keytypes/:keyTypeId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyType parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keystatuses'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyStatuses parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keystatuses/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyStatus parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/keystatuses/:keyStatusId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyStatus parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/keyholders'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyHolders parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/keyreport'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<KeyReport parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/deanslist'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<DeansList parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/emergencyroomlist'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<EmergencyRoomList parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/addsdeletes'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<AddsDeletes parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/gradspostdocs'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<GradsPostdocs parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/locationlist'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<LocationList parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/reports/collegeroomcontacts'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<CollegeRoomContacts parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/departments'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Departments parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/departments/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Department parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/departments/:departmentId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Department parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/buildings'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Buildings parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/buildings/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Building parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/buildings/:buildingId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<Building parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/payrolltitles'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<PayrollTitles parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/payrolltitles/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<PayrollTitle parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/payrolltitles/:payrollTitleId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<PayrollTitle parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/roletypes'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<RoleTypes parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/roletypes/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<RoleType parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/roletypes/:roleTypeId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<RoleType parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/orgunits'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<OrgUnits parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/orgunits/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<OrgUnit parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/orgunits/:orgUnitId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<OrgUnit parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/worktitles'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<WorkTitles parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/worktitles/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<WorkTitle parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/worktitles/:workTitleId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<WorkTitle parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/applications'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel === 1}>
									<Applications parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/applications/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel === 1}>
									<Application parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/applications/:applicationId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel === 1}>
									<Application parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/accessLevels'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel === 1}>
									<AccessLevels parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/accesslevels/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel === 1}>
									<AccessLevel parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/accesslevels/:accessLevelId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel === 1}>
									<AccessLevel parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/authorizations'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel === 1}>
									<Authorizations parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/authorizations/new'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel === 1}>
									<Authorization parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/authorizations/:authorizationId'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel === 1}>
									<Authorization parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/studentupload'} element={
								<Restricted authorized={this.props.user.bldgMgmtAccessLevel > 0}>
									<StudentUpload parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/confirm'} element={<Confirm parent={this} user={this.props.user} message={this.state.confirm} />} />
							<Route path={'/help'} element={<Help parent={this} user={this.props.user} />} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

// routes defined here so there are no accidents when removing them!
const routes = {
	accessLevels: '/BuildingAccess/AccessLevels',
	addsDeletesReport: '/BuildingAccess/Reports/AddsDeletes',
	adHocPeopleReport: '/BuildingAccess/Reports/AdHocPeople',
	applications: '/BuildingAccess/Applications',
	authorizations: '/BuildingAccess/Authorizations',
	buildings: '/BuildingAccess/Buildings',
	collegeRoomContacts: '/BuildingAccess/Reports/CollegeRoomContacts',
	deansList: '/BuildingAccess/Reports/DeansList',
	departments: '/BuildingAccess/Departments',
	duplicatePeopleReport: '/BuildingAccess/Reports/DuplicatePeople',
	emergencyRoomList: '/BuildingAccess/Reports/EmergencyRoomList',
	gradsPostdocs: '/BuildingAccess/Reports/GradsPostdocs',
	keys: '/BuildingAccess/Keys',
	keyHolders: '/BuildingAccess/Reports/KeyHolders',
	keyMakers: '/BuildingAccess/KeyMakers',
	keyReport: '/BuildingAccess/Reports/KeyReport',
	keyStatuses: '/BuildingAccess/KeyStatuses',
	keyTypes: '/BuildingAccess/KeyTypes',
	locationList: '/BuildingAccess/Reports/LocationList',
	myRequests: '/BuildingAccess/MyRequests',
	myGrads: '/BuildingAccess/MyGrads',
	myGroup: '/BuildingAccess/MyGroup',
	myRecord: '/BuildingAccess/MyRecord',
	orgUnits: '/BuildingAccess/OrgUnits',
	payrollTitles: '/BuildingAccess/PayrollTitles',
	peopleSearch: '/BuildingAccess/People',
	roleTypes: '/BuildingAccess/RoleTypes',
	rooms: '/BuildingAccess/Rooms',
	roomFunctions: '/BuildingAccess/RoomFunctions',
	roomReport: '/BuildingAccess/Reports/RoomReport',
	storeroom: '/Stores',
	storesApproval: '/BuildingAccess/StoresApproval',
	storesAccess: '/BuildingAccess/StoresAccess',
	studentUpload: '/BuildingAccess/StudentUpload',
	submittedNewRequests: '/BuildingAccess/Reports/SubmittedNewRequests',
	workshopApproval: '/BuildingAccess/StudentWorkshopApproval',
	workshopAccess: '/BuildingAccess/StudentWorkshopAccess',
	workTitles: '/BuildingAccess/WorkTitles'
};

const treeMenuItems = [
	{
		label: 'Admin',
		nodes: [
			{
				route: routes.myRequests,
				label: 'Appointment Requests'
			},
			{
				route: routes.myGroup,
				label: 'My Group/Unit'
			},
			{
				route: routes.peopleSearch,
				label: 'People Search'
			},
			{
				route: routes.rooms,
				label: 'Rooms'
			},
			{
				route: routes.roomFunctions,
				label: 'Room Functions'
			},
			{
				route: routes.keys,
				label: 'Keys'
			},
			{
				route: routes.keyMakers,
				label: 'Key Makers'
			},
			{
				route: routes.keyTypes,
				label: 'Key Types'
			},
			{
				route: routes.keyStatuses,
				label: 'Key Statuses'
			},
			{
				route: routes.departments,
				label: 'Departments'
			},
			{
				route: routes.buildings,
				label: 'Buildings'
			},
			{
				route: routes.payrollTitles,
				label: 'Payroll Titles'
			},
			{
				route: routes.roleTypes,
				label: 'Role Types'
			},
			{
				route: routes.orgUnits,
				label: 'Organizational Units'
			},
			{
				route: routes.workTitles,
				label: 'Work Titles'
			},
			{
				route: routes.applications,
				label: 'Applications'
			},
			{
				route: routes.accessLevels,
				label: 'Access Levels'
			},
			{
				route: routes.authorizations,
				label: 'Authorizations'
			},
			{
				route: routes.studentUpload,
				label: 'Student Upload'
			}
		]
	},
	{
		label: 'Stores',
		nodes: [
			{
				route: routes.storesApproval,
				label: 'Storeroom Approval'
			},
			{
				route: routes.storesAccess,
				label: 'Stores Access List'
			},
			{
				route: routes.storeroom,
				label: 'Storeroom App'
			}
		]
	},
	{
		label: 'Workshop',
		nodes: [
			{
				route: routes.workshopApproval,
				label: 'Student Work Shop'
			},
			{
				route: routes.workshopAccess,
				label: 'Student Shop Access List'
			}
		]
	},
	{
		label: 'Reports',
		nodes: [
			{
				route: routes.adHocPeopleReport,
				label: 'Ad Hoc People'
			},
			{
				route: routes.duplicatePeopleReport,
				label: 'Duplicate People'
			},
			{
				route: routes.submittedNewRequests,
				label: 'Submitted New Requests'
			},
			{
				route: routes.roomReport,
				label: 'Room Report'
			},
			{
				route: routes.keyHolders,
				label: 'Key Holders'
			},
			{
				route: routes.keyReport,
				label: 'Key Report'
			},
			{
				route: routes.deansList,
				label: "Dean's List"
			},
			{
				route: routes.emergencyRoomList,
				label: 'Emergency Room List'
			},
			{
				route: routes.addsDeletesReport,
				label: 'Adds/Deletes'
			},
			{
				route: routes.gradsPostdocs,
				label: 'Grads & Postdocs'
			},
			{
				route: routes.locationList,
				label: 'Location List'
			},
			{
				route: routes.collegeRoomContacts,
				label: 'College Room Contacts'
			}
		]
	},
	{
		route: routes.myGrads,
		label: 'Grad Students'
	},
	{
		route: routes.myRecord,
		label: 'My Record'
	}
];

export default withBADefaultRoute(BuildingAccess);